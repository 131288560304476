.closure-report-comment-wrapper {
  background-color: #2c685e;
  height: 80vh;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;

  .closure-report-comment-header {
    height: 10%;
    justify-content: space-evenly;
    align-items: center;
    background-color: #e1ae86;

    .header-icon-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .main-icon {
        height: 70%;
        width: auto;
      }
    }

    .header-title-wrapper {
      color: #3d6542;
      font-weight: 900;

      .header-title {
        font-weight: inherit;
        font-size: calc(15px + 2vmin);
      }
    }
  }

  .closure-report-comment-content {
    background-color: #dacbc3;
    height: 83%;
    .content-scores {
      width: 100%;
      background-color: #efc3a0;
      color: #3d6542;
      font-weight: bold;
    }

    .content-comment {
      background-color: #e9c692;
      color: black;

      .content-comment-section {
        .comment-section-header {
          p {
            font-weight: bold
          }
        }
        .comment-section-content {
          text-align: start;
          padding: 0 4%;
          p {
            font-size: calc(6px + 1vmin);
          }

        }
      }
    }

    .content-review {
      background-color: #dacbc3;
      color: #3d6542;
      font-weight: 900;
      padding: 3%;

      .review-title {
        font-weight: inherit;
        font-size: calc(12px + 2vmin);
      }
    }
  }

  .closure-report-comment-signature {
    background-color: #dacbc3;
    height: 7%;
    align-items: center;
    justify-content: end;

    .content-signature-wrapper {
      color: #3d6542;
      padding-right: 2%;
      span {
        font-size: calc(2.5vmin);
      }
    }
  }
}
