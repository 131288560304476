.closure-report-header {
  position: relative;
  background-color: #2c685e;
  height: 20vh;
  align-items: center;

  .closure-report-header-head {
    padding: 1% 2%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .main-title {
      width: 55%;
      text-align: start;
      font-size: calc(14px + 3vmin);
      font-weight: bold;
      line-height: 90%;
      color: #ffe8cd;
    }

    .sub-title {
      text-align: start;
      font-size: calc(7px + 1.5vmin);
      color: #ffc388;
      padding-top: 2%;
    }
  }

  .closure-report-header-tail {
    background-color: #efe9db;
    width: 100%;
  }

  .closure-report-header-img-wrapper {
    position: absolute;
    top: 2vh;
    right: 4%;
    width: 50%;
    height: 16vh;

    .closure-report-header-img {
      width: auto;
      height: 100%;
    }
  }
}
