#form {
    text-align: center;
}
.form-wrapper {
    margin: auto;
    align-items: center;
    max-width: 770px;
}
.form {
    padding-top: 3%;

    .form-header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .header-title-wrapper {
            width: 100%;
        }
    }
}
.total-score-wrapper {
    .score-input {
        width: 3rem;
    }
}
.detail-score-wrapper {
    border: solid;
}
.comprehensive {
    border-color: #78AAA7;
    border-radius: 6px 6px 0 0;
    border-width: 4px 4px 0;
}
.academic {
    border-color: #DD9735;
    border-radius: 0 0 6px 6px;
    border-width: 0 4px 4px;
}
.closure-score-wrapper {
    padding: 10%;
}
@media (min-width:771px) {
    .total-score-wrapper {
        width: 20%;
        .score-input {
            width: 5rem;
        }
    }
}