.Report-comment {
  background-color: #2c685e;
  height: 55vh;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;

  .content-group {
    height: 93%;
    justify-content: space-evenly;
    align-items: center;

    .content-wrapper {
      height: 100%;
      padding: 1% 1% 0 1%;

      .content {
        padding: 2%;
        height: 100%;

        .content-header {
          height: 10%;
          background-color: #F8E9CE;
          border-radius: 6px;
          align-items: center;

          .content-header-title-wrapper {
            color: #30656B;
            font-weight: 900;

            .content-header-title {
              font-weight: inherit;
              font-size: calc(15px + 2vmin);
            }
          }

          .content-header-icon-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            .content-header-icon {
              width: 60%;
              height: auto;
            }
          }
        }

        .content-box {
          height: 45%;

          .content-box-title {
            align-items: center;
            height: 35%;
            font-size: calc(10px + 2vmin);

            .content-box-icon-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;

              .content-icon {
                width: 70%;
                height: auto;
              }
            }

            .content-box-text-wrapper {
              .content-box-text {
                font-size: calc(10px + 2vmin);
              }
              .content-box-text::selection {
                background-color: #282c34;
              }
            }
          }

          .content-box-body {
            height: 65%;
            border-style: dashed;
            padding: 2%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: start;
          }
        }
      }
    }

    .comprehensive {
      background-color: #78AAA7;
    }
    .academic {
      background-color: #DD9735;
    }
  }

  .content-signature {
    height: 7%;
    align-items: center;
    justify-content: end;

    .content-signature-wrapper {
      color: #FFE8CD;
      padding-right: 2%;
      span {
        font-size: calc(2.5vmin);
      }
    }
  }
}
