.capture-wrapper {
  text-align: center;
  padding: 2%;
  .tip-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }
  .capture {
    width: 100%;
    height: auto;
    opacity: 0.6;
  }
}
