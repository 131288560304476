.Report-header {
  background-color: #2c685e;
  height: 15vh;
  align-items: center;
  color: white;

  .header-icon-wrapper {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    .main-icon {
      height: 70%;
      width: auto;
    }
  }
}


.main-title {
  font-size: calc(18px + 3vmin);
  color: #dd9735;
}

.sub-title {
  font-size: calc(10px + 3vmin);
  color: #fbe9ce;
}
