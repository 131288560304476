.Report-stats {
  background-color: #d9f1ea;
  height: 30vh;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 1%;

  .score {
    height: 45%;

    .figure {
      height: 80%;
      align-items: center;

      .graph {
        height: 80%;
        position: relative;

        .axis {
          padding: 2%;
          height: 100%;
          position: absolute;

          .pod {
            border: 1px #ecab82;
            border-left-style: solid;
            width: 16%;
            position: relative;

            .p {
              position: absolute;
              left: -10%;
              bottom: -30%;
              width: 20%;
              height: 30%;
              color: black;
              p {
                font-size: 0.5em;
              }
            }

            .end {
              position: absolute;
              right: -10%;
              bottom: -30%;
              width: 20%;
              height: 30%;
              color: black;
              p {
                font-size: 0.5em;
              }
            }
          }

          .big-pod {
            width: 20%;
          }

          .last {
            border-style: none solid;
          }
        }
        .shrink {
          width: 96%;
        }

        .chart {
          padding: 2%;
          height: 100%;
          width: 96%;
          position: absolute;
          justify-content: center;

          .bar-wrapper {
            height: 25%;
            align-items: center;

            .bar-column {
              height: 100%;
            }
          }
        }

        .indicator-bar-container {
          padding: 2%;
          height: 100%;
          width: 96%;
          position: absolute;
          justify-content: center;

          .indicator-bar {
            height: 25%;
            .bar-column {
              height: 100%;
            }
            .pseudo {
              background-color: transparent;
              justify-content: center;
              .bar-label-wrapper {
                width: fit-content;
                padding-left: 0.26rem;
                font-size: calc(8px + 1vmin);
                color: black;
                position: relative;
                left: 100%;
              }
            }
          }
        }

        .sub {
          width: 100%;
          height: 50%;
          padding: 0;
          position: relative;
        }
      }
    }

    .detail-label-group {
      height: 80%;
      padding: 2%;
      .detail-label {
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: black;
          font-size: 0.6em;
        }
      }
    }

    .label-wrapper {
      border-style: dashed;
      border-color: #0f676c;
      padding: 1%;

      .label {
        color: black;
        font-size: calc(5px + 2vmin);
      }
    }

    .legend {
      justify-content: center;

      .legend-pod {
        align-items: center;
        justify-content: center;
      }
      p {
        padding-left: 3%;
        font-size: 0.6em;
        color: black;
      }
    }
  }

  .note {
    width: 90%;
    height: 10%;
    background-color: #0f676c;
    border-radius: 6px;
    color: #ffe8cd;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: calc(2.5vmin);
    }
  }
}

.reference {
  background-color: #0f676c;
  color: #0f676c;
}
.current {
  background-color: #dd9735;
  color: #dd9735;
}
.bar-column {
  height: 100%;
}
.squareIcon {
  width: 0.6em!important;
  height: 0.6em!important;
}